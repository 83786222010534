import React from "react";
import Styled from "styled-components";

export default Styled.div`
width: 100%;
    max-width: 100%;
    height:1.5px;
    background: ${props =>
      props.light ? "rgba(250,250,250,0.3)" : "rgba(0,0,0,0.1)"};
`;
