import React from "react"
import { graphql } from "gatsby"

import ResourceIndexPage from "../../components/resources/resourceIndexPage"

const FreeConstructionTemplates = ({ data }) => {
  const {
    constructionTemplates: {
      Image,
      seo: { description, titleseo },
      TopBlurb,
      Title,
    },
    resources: { nodes: resourceNodes },
    categories: { nodes: categoryNodes },
  } = data
  return (
    <ResourceIndexPage
      categories={categoryNodes}
      resources={resourceNodes}
      image={Image.childImageSharp.fluid}
      topblurb={TopBlurb}
      title={Title}
      description={description}
      titleseo={titleseo}
    />
  )
}

export const query = graphql`
  {
    constructionTemplates: strapiConstructionExcelTemplates {
      seo {
        titleseo
        description
      }
      Title
      TopBlurb
      Image {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100, cropFocus: NORTH) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    resources: allStrapiResource(
      filter: { resource_category: { type: { eq: "construction" } } }
    ) {
      nodes {
        thumbnailblurb
        thumb {
          childImageSharp {
            fluid(maxWidth: 160, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        slug
        Title
        resource_category {
          Title
          type
        }
      }
    }
    categories: allStrapiResourceCategory(
      filter: { type: { eq: "construction" } }
    ) {
      nodes {
        type
        Title
      }
    }
  }
`

export default FreeConstructionTemplates
