import React from "react"
import { Container, Row } from "react-grid-system"

import NormalResource from "./normalResource"
import SignResource from "./signResource"

const ResourceCategoyThumbList = ({ resources, title }) => {
  return (
    <Container style={{ marginTop: "2rem" }}>
      <h2 style={{ marginBottom: "4rem" }}>{title}</h2>
      <Row style={{ width: "100%", marginTop: "2rem" }}>
        {resources.map(resource => {
          if (resource.resourceType === "Sign") {
            return <SignResource resource={resource} />
          }
          return <NormalResource resource={resource} />
        })}
      </Row>
    </Container>
  )
}

export default ResourceCategoyThumbList
