import React from "react"
import Image from "gatsby-image"
import Layout from "../layout"
import SEO from "../seo"
import FullWidthSection from "../layout/fullWidthSection"
import InnerContentContainer from "../layout/innerContentContainer"
import ResourceCategoryThumbList from "./resourceCategoryThumbList"

const ResourceIndexPage = ({
  title,
  topblurb,
  titleseo,
  description,
  categories,
  resources,
  image,
}) => (
  <Layout>
    <SEO title={titleseo} description={description} />
    <FullWidthSection style={{ paddingBottom: "3rem", paddingTop: "3rem" }}>
      <InnerContentContainer center>
        <h1>{title}</h1>
        <h4>{topblurb}</h4>
      </InnerContentContainer>
    </FullWidthSection>
    <div>
      <Image style={{ height: 300 }} fluid={image} alt={titleseo} />
    </div>
    <FullWidthSection>
      <InnerContentContainer center>
        {categories.map(category => {
          const filteredResources = resources.filter(
            resource => resource.resource_category.Title === category.Title
          )
          return (
            <ResourceCategoryThumbList
              title={category.Title}
              resources={filteredResources}
            />
          )
        })}
      </InnerContentContainer>
    </FullWidthSection>
  </Layout>
)

export default ResourceIndexPage
