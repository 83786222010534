import React from "react"
import Markdown from "react-markdown"
import { Link } from "gatsby"
import Image from "gatsby-image"

import Separator from "../layout/separator"
import Column from "../layout/column"

import { defaultRenderers } from "../../constants/mdxRenderers"

const NormalResource = ({ resource }) => (
  <>
    <Column lg={9} md={8} sm={12} style={{ textAlign: "left" }}>
      <Link to={`/tools/${resource.slug}`}>
        <p className="subheader">{resource.Title}</p>
        <Markdown
          children={resource.thumbnailblurb}
          components={defaultRenderers}
        />
      </Link>
    </Column>
    <Column lg={3} md={4} sm={12}>
      <Link to={`/tools/${resource.slug}`}>
        <div
          style={{
            width: "100%",
            maxWidth: 300,
            borderRadius: 5,
            border: "1px solid rgba(0,0,0,0.1)",
            margin: "0px auto",
          }}
        >
          <Image
            alt={resource.Title}
            fluid={resource.thumb.childImageSharp.fluid}
          />
        </div>
      </Link>
    </Column>

    <Separator style={{ marginBottom: "2rem", marginTop: "2rem" }} />
  </>
)

export default NormalResource
