import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

import Column from "../layout/column"

const SignResource = ({ resource }) => (
  <>
    <Column lg={2} md={3} sm={6} style={{ textAlign: "center" }}>
      <Link to={`/tools/${resource.slug}`}>
        <div
          style={{
            width: "100%",
            maxWidth: 300,
            borderRadius: 5,
            overflow: "hidden",
            border: "1px solid rgba(0,0,0,0.1)",
            margin: "0px auto 15px",
          }}
        >
          <Image
            alt={resource.Title}
            fluid={resource.thumb.childImageSharp.fluid}
          />
        </div>
        <p className="sign-subheader">{resource.Title}</p>
      </Link>
    </Column>
  </>
)

export default SignResource
